var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","display":"unset"}},[_c('title',[_vm._v("MC Makelaardij | Contact informatie wijzigen")]),_c('div',{staticClass:"topnav",attrs:{"id":"myTopnav"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png"),"alt":"KantoorFlex","title":"Flexplekken in Nederland logo"}})]),_c('div',{staticClass:"links"},[_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Flexplekken' }}},[_vm._v("Ons aanbod")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Overons' }}},[_vm._v("Over ons")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'WaaromKantoorflex' }}},[_vm._v("Waarom KantoorFlex")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Contact' }}},[_vm._v("Contact")]),(!_vm.isLoggedIn)?_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Inloggen' }}},[_vm._v(" Mijn kantoorflex")]):_vm._e(),(_vm.isLoggedIn)?_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'KlantPortaal' }}},[_vm._v(" Portaal")]):_vm._e(),(_vm.isLoggedIn)?_c('router-link',{attrs:{"to":{ name: 'Inloggen' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
return [_c('a',{staticClass:"menu-item",attrs:{"href":href},on:{"click":function () {
              _vm.logOut();
              navigate();
            }}},[_vm._v("Uitloggen")])]}}],null,false,2597627225)}):_vm._e()],1),_c('div',{attrs:{"id":"hamburger"},on:{"click":function($event){return _vm.openSidebar()}}},[_c('div',{on:{"click":function($event){return _vm.openSidebar()}}}),_c('div',{on:{"click":function($event){return _vm.openSidebar()}}}),_c('div',{on:{"click":function($event){return _vm.openSidebar()}}})])],1),_c('div',{attrs:{"id":"sidebar"}},[_c('div',{attrs:{"id":"cross"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('span',[_vm._v("×")])]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Flexplekken' }}},[_vm._v("Ons aanbod")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Overons' }}},[_vm._v("Over ons")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'WaaromKantoorflex' }}},[_vm._v("Waarom KantoorFlex")]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Contact' }}},[_vm._v("Contact")]),(!_vm.isLoggedIn)?_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Inloggen' }}},[_vm._v(" Mijn kantoorflex")]):_vm._e(),(_vm.isLoggedIn)?_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'KlantPortaal' }}},[_vm._v(" Portaal")]):_vm._e(),(_vm.isLoggedIn)?_c('router-link',{attrs:{"to":{ name: 'Inloggen' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
            var href = ref.href;
return [_c('a',{staticClass:"menu-item",attrs:{"href":href},on:{"click":function () {
            _vm.logOut();
            navigate();
          }}},[_vm._v("Uitloggen")])]}}],null,false,2517743321)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }