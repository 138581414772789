import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';
import axios from 'axios';

Vue.use(VueRouter);
const isLoggedIn = () => firebase.auth().currentUser;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title: ' KantooorFlex | Huren van een kantoorRuimte in Nederland',
    },
  },
  {
    path: '/Overons',
    name: 'Overons',
    component: () =>
      import(/* webpackChunkName: "Overons" */ '../views/Overons.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Flexibele kantoorruimte | Kantoorflex',
    },
  },

  {
    path: '/WaaromKantoorflex',
    name: 'WaaromKantoorflex',
    component: () =>
      import(
        /* webpackChunkName: "WaaromKantoorflex" */ '../views/WaaromKantoorflex.vue'
      ),
    meta: {
      title: ' Flexplek huren verhuren | waarom kantoorflex | Kantoorflex',
    },
  },

  {
    path: '/Flexplekken',
    name: 'Flexplekken',
    component: () =>
      import(/* webpackChunkName: "Werkplekken" */ '../views/Flexplekken.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Flexibele kantoorruimte | Kantoorflex',
    },
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "Overons" */ '../views/Contact.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Contact opnemen | Kantoorflex',
    },
  },


  {
    path: '/Payment-confirmation',
    name: 'Payment-confirmation',
    component: () =>
      import(/* webpackChunkName: "Overons" */ '../views/PaymentConfirmation.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Payment-confirmation opnemen | Kantoorflex',
    },
  },


  

  {
    path: '/Blogs',
    name: 'Blogs',
    component: () =>
      import(/* webpackChunkName: "Blog" */ '../views/Blogs.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Blog opnemen | Kantoorflex',
    },
  },

  {
    path: '/SingleBlog/:id',
    name: 'SingleBlog',
    component: () =>
      import(/* webpackChunkName: "singleHouse" */ '@/views/SingleBlog.vue'),
    meta: { title: ' MC MCmakelaardij | Huis informatie' },
  },

  {
    path: '/SingleLocation/:id',
    name: 'SingleLocation',
    component: () =>
      import(
        /* webpackChunkName: "singleHouse" */ '@/views/SingleLocation.vue'
      ),
    meta: { title: ' Kantooflex | Information locatie' },
  },

  {
    path: '/Privacyvoorwaarden',
    name: 'Privacyvoorwaarden',
    component: () =>
      import(
        /* webpackChunkName: "Privacy" */ '../views/Privacyvoorwaarden.vue'
      ),
    meta: {
      title: ' Flexplek huren verhuren | Flexibele kantoorruimte | Kantoorflex',
    },
  },

  {
    path: '/Algemenevoorwaarden',
    name: '/Algemenevoorwaarden',
    component: () =>
      import(
        /* webpackChunkName: "Privacy" */ '../views//Algemenevoorwaarden.vue'
      ),
    meta: {
      title:
        ' Flexplek/Algemenevoorwaarden| Flexibele kantoorruimte | Kantoorflex',
    },
  },

  {
    path: '/Inloggen',
    name: 'Inloggen',
    component: () =>
      import(/* webpackChunkName: "Inloggen" */ '../views/Inloggen.vue'),
    meta: {
      title: ' Flexplek huren verhuren | Inloggen | Kantoorflex',
    },
  },

  // Portal

  {
    path: '/Dashboard',
    name: 'AdminDashboard',
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ '@/layouts/AdminLayout.vue'),
    meta: { requiresAuth: true, accessRights: ['MAKELAAR'] },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ '@/views/portal/Dashboard.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: [ 'MAKELAAR'],
          showFooter: false,
          title: ' Kantoorflex | Dashboard ',
        },
      },

      {
        path: '/Mijn-Account',
        name: 'Mijn-Account',
        component: () =>
          import(
            /* webpackChunkName: "Mijn-Account" */ '../views/portal/Mijn-Account.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Mijn account| Kantoorflex',
        },
      },

      {
        path: '/Agenda',
        name: 'Agenda',
        component: () =>
          import(/* webpackChunkName: "Agenda" */ '../views/portal/Agenda.vue'),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Mijn account| Kantoorflex',
        },
      },

      {
        path: '/Reviews',
        name: 'Reviews',
        component: () =>
          import(
            /* webpackChunkName: "Reviews" */ '../views/portal/Reviews.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Mijn Reviews| Kantoorflex',
        },
      },

      {
        path: '/Instellingen',
        name: 'Instellingen',
        component: () =>
          import(
            /* webpackChunkName: "Instellingen" */ '../views/portal/Instellingen.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Kantoorflex Instellingen | Kantoorflex',
        },
      },

      {
        path: '/Facturen',
        accessRights: ['MAKELAAR'],
        name: 'Facturen',
        component: () =>
          import(
            /* webpackChunkName: "Facturen" */ '../views/portal/Facturen.vue'
          ),
        meta: {
          requiresAuth: true,
          showFooter: false,
          title: ' Mijn facturen| Kantoorflex',
        },
      },

      {
        path: '/Werkplekken',
        accessRights: ['MAKELAAR'],
        name: 'Werkplekken',
        component: () =>
          import(
            /* webpackChunkName: "Inloggen" */ '../views/portal/Werkplekken.vue'
          ),
        meta: {
          requiresAuth: true,
          showFooter: false,
          title: ' flexplekken beheren| Inloggen | Kantoorflex',
        },
      },

      {
        path: '/Flexplektoevoegen',
        name: 'Flexplektoevoegen',
        component: () =>
          import(
            /* webpackChunkName: "Inloggen" */ '../views/portal/Flexplektoevoegen.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Flexplek huren verhuren | Flexplek toevoegen',
        },
      },

      {
        path: '/Flexplekwijzigen/:id',
        name: 'Flexplekwijzigen',
        component: () =>
          import(
            /* webpackChunkName: "Flexplekwijzigen" */ '../views/portal/Flexplekwijzigen.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Kantoorflex Nederland | Flexplek wijzigen',
        },
      },

      {
        path: '/Boekingen',
        name: 'Boekingen',
        component: () =>
          import(
            /* webpackChunkName: "Mijn-Account" */ '../views/portal/Boekingen.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Mijn account| Kantoorflex',
        },
      },
      {
        path: '/Plug-ins',
        name: 'Plug-ins',
        component: () =>
          import(
            /* webpackChunkName: "Mijn-Account" */ '../views/portal/Plug-ins.vue'
          ),
        meta: {
          requiresAuth: true,
          accessRights: ['MAKELAAR'],
          showFooter: false,
          title: ' Mijn account| Kantoorflex',
        },
      },
    ],
  },

  //USer portal
  {
    path: '/KlantPortaal',

    name: 'KlantPortaal',
    component: () =>
      import(
        /* webpackChunkName: "KlantPortaal" */ '../views/userPortal/KlantPortaal.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['CUSTOMER'],
      title: ' Flexplek Nederland | Gebruikersportaal',
    },
  },

  {
    path: '/Mijngegevens',
    name: 'Mijngegevens',
    component: () =>
      import(
        /* webpackChunkName: "Mijngegevens" */ '../views/userPortal/Mijngegevens.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['CUSTOMER'],
      title: ' Flexplek Nederland | Mijn gegevens',
    },
  },

  {
    path: '/Mijnfacturen',
    name: 'Mijnfacturen',
    component: () =>
      import(
        /* webpackChunkName: "Mijnfacturen" */ '../views/userPortal/Mijnfacturen.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['CUSTOMER'],
      title: ' Flexplek Nederland | Mijn facturen beheren',
    },
  },


  {
    path: '/InstellingenBeheren',
    name: 'InstellingenBeheren',
    component: () =>
      import(
        /* webpackChunkName: "Instellingen" */ '../views/userPortal/InstellingenBeheren.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['CUSTOMER'],
      title: ' Flexplek Nederland | Mijn instellingen beheren',
    },
  },

  {
    path: '/Reservingen',
    name: 'Reserveringen',
    component: () =>
      import(
        /* webpackChunkName: "Reserveringen" */ '../views/userPortal/Reserveringen.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['CUSTOMER'],
      title: ' Flexplek Nederland | Reserveringenbeheer',
    },
  },

  {
    path: '/Kennissysteem',
    name: 'Kennissysteem',
    component: () =>
      import(
        /* webpackChunkName: "Kennissysteem" */ '../views/userPortal/Kennissysteem.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['CUSTOMER'],
      title: ' Flexplek Nederland | Kennissysteem',
    },
  },
  //Admin portal
  {
    path: '/AdminPortaal',
    name: 'AdminPortaal',
    component: () =>
      import(
        /* webpackChunkName: "AdminDashboard" */ '../views/admin/AdminPortaal.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN'],
      title: ' Flexplek Nederland | Kennissysteem',
    },
  },

  {
    path: '/AdministratieBeheren',
    name: 'AdministratieBeheren',
    component: () =>
      import(
        /* webpackChunkName: "AdministratieBeheren" */ '../views/admin/AdministratieBeheren.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN'],
      title: ' Flexplek Nederland | Kennissysteem',
    },
  },

  {
    path: '/Analytics',
    name: 'Analytics',
    component: () =>
      import(
        /* webpackChunkName: "Gebruikerbeheer" */ '../views/admin/Analytics.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN', 'WERKNEMER'],
      title: ' Flexplek huren verhuren | Flexplek toevoegen',
    },
  },
  {
    path: '/AlleGebruikers',
    name: 'AlleGebruikers',
    component: () =>
      import(
        /* webpackChunkName: "Gebruikerbeheer" */ '../views/admin/AlleGebruikers.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN', 'WERKNEMER'],
      title: ' Flexplek huren verhuren | Flexplek toevoegen',
    },
  },

  {
    path: '/AlleWerkPlekken',
    name: 'AlleWerkPlekken',
    component: () =>
      import(
        /* webpackChunkName: "AlleWerkPlekken" */ '../views/admin/AlleWerkPlekken.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN', 'WERKNEMER'],
      title: ' Alle werkplekken | Flexplek toevoegen',
    },
  },

  {
    path: '/KennisItems',
    name: 'KennisItems',
    component: () =>
      import(
        /* webpackChunkName: "KennisItems" */ '../views/admin/KennisItems.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN', 'WERKNEMER'],
      title: ' Updates beheren | Flexplek toevoegen',
    },
  },

  {
    path: '/UpdatesBeheer',
    name: 'UpdatesBeheer',
    component: () =>
      import(
        /* webpackChunkName: "UpdatesBeheer" */ '../views/admin/UpdatesBeheer.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN', 'WERKNEMER'],
      title: ' Updates beheren | Flexplek toevoegen',
    },
  },

  {
    path: '/ReserveringBeheer',
    name: 'ReserveringBeheer',
    component: () =>
      import(
        /* webpackChunkName: "ReserveringBeheer" */ '../views/admin/ReserveringBeheer.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN', 'WERKNEMER'],
      title: ' ReserveringBeheer| Flexplek toevoegen',
    },
  },

  {
    path: '/BlogBeheren',
    name: 'BlogBeheren',
    component: () =>
      import(
        /* webpackChunkName: "Blogeheren" */ '../views/admin/BlogBeheren.vue'
      ),
    meta: {
      requiresAuth: true,
      accessRights: ['ADMIN', 'WERKNEMER'],
      title: ' Blog beheren | Flexplek toevoegen',
    },
  },

  
  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/404.vue'),
    meta: { title: ' Kantoorflex | Oeps pagina niet gevonden' },
  },
];

export async function setFirebaseToken() {
  const token = (await isLoggedIn())
    ? await firebase.auth().currentUser.getIdToken(true)
    : null;
  if (token) axios.defaults.headers.common['fireToken'] = token;
}

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || 'Kantoorflex | Flexplekken in Nederland';
  await setFirebaseToken();
  if (to.meta.requiresAuth) {
    if (!isLoggedIn()) {
      next({
        path: '/Inloggen',
        query: { redirect: to.fullPath }
      })
    }
    const response = await axios.get(
      `https://server.kantoorflex.nl/api/rest/v2/whoami`
    );
    const accessRight = response.data.accessRight;
    if (to.meta.accessRights && !to.meta.accessRights.includes(accessRight)){
      if (["MAKELAAR", "WERKNEMER"].includes(accessRight)) {
        next("/Dashboard")
      } else if (["CUSTOMER"].includes(accessRight)) {
        next("/KlantPortaal")
      }
     else if (["ADMIN"].includes(accessRight)) {
      next("/AdminPortaal")
    }else {
        next("Inloggen")
      }
      return;
    }

  }
  next();
}
const router = new VueRouter({
  mode: 'history',
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);
export default router;
