import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";
import VueMeta from "vue-meta";
import VueSweetalert2 from "vue-sweetalert2";
import AOS from "aos";
import * as VueGoogleMaps from "vue2-google-maps";
import "firebase/auth";

//Validate

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

//Editor
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import "aos/dist/aos.css";
import { VBHover } from "bootstrap-vue";

AOS.init({
  disable: "mobile",
});
//images
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

// QR codes
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

//google
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAm9v46XTOdZfD6lJryxr0y7u_J9bhlm3Q",
    libraries: "places",
  },
  installComponents: true,
});
//AIzaSyDFI9tO_rsPTsbP4Rp2KlNUrInl64Ruy-I

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCuYr_XgCRj7VPl--FDrWGoLReGjXDmWd0",
  authDomain: "kantoorfles.firebaseapp.com",
  projectId: "kantoorfles",
  storageBucket: "kantoorfles.appspot.com",
  messagingSenderId: "841440400593",
  appId: "1:841440400593:web:66dd02c54ae36917be61f0",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

let app;

firebase.auth().onAuthStateChanged(async function() {
  if (!app) {
    new Vue({
      router,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
    app = true;
  }
});
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
