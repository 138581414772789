<template>
  <v-footer color="primary lighten-1" padless>
    <v-row justify="center" no-gutters>
      <v-btn v-for="link in links"
       :key="link.text"
       :to="link.to"
        color="white" text rounded class="my-2">
        {{ link.text }}
      </v-btn>
      <v-col dark class="py-4 text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>KantoorFlex</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      { text: 'Home', to: "/" },
      { text: 'Over ons', to: "/Overons" },
      { text: 'Blog', to: "/Blogs" },
      { text: 'Contact', to: "/Contact" },
      { text: 'Algemene voorwaarden', to: "/Algemenevoorwaarden" },
      { text: 'Privacy voorwaarden', to: "/Privacyvoorwaarden" },
    ],
  }),
}
</script>